<template>
  <div>
    <MainStepper e1="2" />
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="id"
      class="elevation-1"
      loading-text="Loading... Please wait"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">

           <v-row>
            <v-col cols="6">
              <span class="text-h6 primary--text">Approval Workflow</span>
            </v-col>
            <v-col cols="6">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="gotToMyApprovals"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    x-small
                  >
                    <v-icon>mdi-clipboard-check-outline</v-icon>
                  </v-btn>
                </template>
                <span>My approval requests</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="gotToRequisition"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-format-list-bulleted-square</v-icon>
                  </v-btn>
                </template>
                <span>Back to requisitions</span>
              </v-tooltip>
<v-dialog v-model="dialog" max-width="550px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    fab
                    x-small
                  >
                    <v-icon>mdi-plus-thick</v-icon>
                  </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title>
                    <span class="headline error--text text--darken-1">{{
                      formTitle
                    }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="my-n2">
                          <v-text-field
                            v-model="editedItem.title"
                            label="Title"
                            :rules="rules.required"
                            color="grey"
                            dense
                            outlined
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Name
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="my-n5">
                          <v-textarea
                            v-model="editedItem.description"
                            outlined
                            name="input-7-4"
                            label="Tender Details"
                            color="grey"
                            dense
                            :rules="rules.required"
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Description
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-textarea>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" class="mt-1">
                          <v-select
                            v-model="editedItem.approval_groups"
                            :value="editedItem.approval_groups"
                            :items="approval_groups"
                            :rules="rules.select2"
                            label="Approval Groups"
                            item-text="title"
                            item-value="id"
                            multiple
                            outlined
                            chips
                            color="grey"
                            item-color="grey"
                            class="my-1 mx-1"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Approval Groups
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                            <template v-slot:prepend-item>
                              <v-list-item ripple @click="toggleRequired">
                                <v-list-item-action>
                                  <v-icon
                                    :color="
                                      editedItem.approval_groups.length > 0
                                        ? 'error'
                                        : ''
                                    "
                                  >
                                    {{ iconRequired }}
                                  </v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Select All
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:append-item>
                              <v-divider class="mb-2"></v-divider>
                              <v-list-item disabled>
                                <v-list-item-avatar color="grey lighten-3">
                                  <v-icon> mdi-file-outline </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content
                                  v-if="likesAllApprovalGroup"
                                >
                                  <v-list-item-title>
                                    All Documents selected for mandatory fields!
                                  </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-content
                                  v-else-if="likesSomeapproval_groups"
                                >
                                  <v-list-item-title>
                                    Document Count
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    {{ editedItem.approval_groups.length }}
                                  </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-content v-else>
                                  <v-list-item-title>
                                    Which documents are mandatory for this
                                    tender?
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    Go ahead, make a selection above!
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      class="text-capitalize mx-1"
                      dark
                      @click="close"
                    >
                      Cancel <v-icon small>mdi-cancel</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      class="text-capitalize mx-1"
                      :loading="loading"
                      @click="save"
                    >
                      Submit <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>






              <v-text-field
                label="search..."
                v-model="search"
                append-icon="mdi-magnify"
                dense
                width="54"
                color="primary"
                class="float-right"
              ></v-text-field>
            </v-col>
          </v-row>

        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>

      <template v-slot:[`item.approval_groups`]="{ item }">
        <div v-if="item.approval_groups.length > 0">
          <a
            v-for="(wkfl, i) in item.approval_groups"
            :key="i"
            class="primary--text"
          >
            <span v-if="i != 0"> <v-icon>mdi-arrow-right-bold</v-icon> </span>
            <a v-if="i == item.status" class="error--text">{{ wkfl.title }}</a>
            <a v-else class="primary--text">{{ wkfl.title }}</a>
          </a>
        </div>
        <div v-else>
          <a class="red--text" @click="editItem(item)">
            Please select workflow steps
          </a>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="my-1">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                <v-btn
                  color="info"
                  class="mx-1"
                  fab
                  x-small
                  v-if="approvalStatus(item)"
                  dark
                  @click="changeWorkflowSteps(item)"
                >
                  <v-icon>mdi-order-alphabetical-ascending</v-icon>
                </v-btn>
              </span>
            </template>
            <span>Change Workflow Order</span>
          </v-tooltip>

          <v-btn
            color="primary"
            class="mx-1"
            fab
            x-small
            dark
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>

          <v-btn
            color="error"
            class="mx-1"
            fab
            x-small
            dark
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <div class="text-center">
      <v-dialog v-model="dialogChangeSteps" width="600">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title>
              <span class="headline error--text text--darken-1"
                >Change Workflow Steps</span
              >
            </v-card-title>
            <v-divider></v-divider>

            <v-card-subtitle>
              <span class="headline grey--text text--darken-1">{{
                editedItem.title
              }}</span>
            </v-card-subtitle>
            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="my-n2">
                    <v-list two-line subheader>
                      <v-subheader>Hold and Drag To Move A Group</v-subheader>

                      <v-list-item>
                        <draggable
                          v-model="editedItem.approval_groups"
                          group="workflow"
                          @start="drag = true"
                          @end="drag = false"
                        >
                          <v-list-item-content
                            v-for="approval_group in editedItem.approval_groups"
                            :key="approval_group.id"
                          >
                            <v-chip color="success" outlined>
                              <v-avatar left>
                                <v-icon>mdi-bullseye</v-icon>
                              </v-avatar>
                              {{ approval_group.description }}
                            </v-chip>
                          </v-list-item-content>
                        </draggable>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col cols="12" sm="12" md="12" class="mt-1">
                    <v-list subheader v-model="editedItem.approval_groups">
                      <v-subheader>Minimum Approvers Per Group</v-subheader>

                      <v-list-item
                        v-for="(group, i) in editedItem.approval_groups"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="group.description"
                          ></v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-icon>
                          <v-text-field
                            v-model="group.approvers"
                            label="Approvers"
                            :rules="rules.approvers"
                            color="grey"
                            dense
                            outlined
                            class="mb-n3"
                          >
                            <template v-slot:label>
                              <span class="input__label"
                                >Approvers
                                <v-icon small color="error" class="mt-n2"
                                  >mdi-star-outline</v-icon
                                ></span
                              >
                            </template>
                          </v-text-field>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-capitalize mx-1"
                dark
                @click="dialogChangeSteps = false"
              >
                Close <v-icon small>mdi-close</v-icon>
              </v-btn>
              <v-btn
                color="success"
                dark
                class="text-capitalize mx-1"
                :loading="loading"
                @click="updateWorkflowSteps(editedItem)"
              >
                Change <v-icon>mdi-radar</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import WorkflowService from "../../services/workflow.service";
import draggable from "vuedraggable";
import MainStepper from "../../components/MainStepper.vue";

export default {
  components: {
    draggable,
    MainStepper,
  },
  data: () => ({
    name: "ApprovalGroup",
    approval_groups: [],
    approval_group_ids: [],
    search: "",
    loading: true,
    items: [],
    groups: [],
    editedIndex: -1,
    editedItem: {
      approval_groups: [],
      approval_group_ids: [],
    },
    defaultItem: {
      approval_groups: [],
      approval_group_ids: [],
    },
    remember: 0,
    timeout: 2000,
    dialog: false,
    dialogChangeSteps: false,
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
      approvers: [(v) => !!v || "Field is required"],
    },
    workflow_steps: [
      {
        id: 1,
        name: "One",
      },
      {
        id: 1,
        name: "Two",
      },
      {
        id: 1,
        name: "Three",
      },
      {
        id: 1,
        name: "Four",
      },
      {
        id: 1,
        name: "Five",
      },
    ],
    selectRules: [(v) => !!v || "Select is required"],
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Workflow Steps",
        value: "approval_groups",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    // required docs
    likesAllApprovalGroup() {
      return (
        this.editedItem.approval_groups.length === this.approval_groups.length
      );
    },
    likesSomeapproval_groups() {
      return (
        this.editedItem.approval_groups.length > 0 &&
        !this.likesAllApprovalGroup
      );
    },
    iconRequired() {
      if (this.likesAllApprovalGroup) return "mdi-close-box";
      if (this.likesSomeapproval_groups) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
    this.getAllGroups();
  },
  methods: {
    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    goToNeedIdentification() {
      this.$router.push("need-identification");
    },
    gotToRequisition() {
      this.$router.push("requisition");
    },
    gotToMyApprovals() {
      this.$router.push("/requisition-approval");
    },
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllApprovalGroup) {
          this.editedItem.approval_groups = [];
          this.editedItem.approval_group_ids = [];
        } else {
          this.editedItem.approval_groups = this.approval_groups.slice();
          this.editedItem.approval_group_ids = this.approval_group_ids.slice();
        }
      });
    },
    getAllGroups() {
      return WorkflowService.getApprovalGroups().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.approval_groups = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    changeWorkflowSteps(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogChangeSteps = true;
    },

    updateWorkflowSteps(item) {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        return WorkflowService.updateWorkflowSteps(item).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.index();
              this.loading = false;
              this.close();
            } else {
              this.items = [];
              console.log(response.data.error);
            }
          },
          (error) => {
            this.items = [];
            console.log(error);
          }
        );
      }
    },

    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    openDocument(item) {
      console.log(item);
      this.$router.replace(item.attachment);
    },
    approvalStatus(item) {
      if (item.approved == 1) {
        return false;
      }

      return true;
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    index() {
      return WorkflowService.index().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        WorkflowService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            if (error.response.status == 401) {
              this.handleLogout();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.dialogChangeSteps = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
    },

    save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          try {
            if (this.editedItem.approval_groups[0].id) {
              this.editedItem.approval_groups =
                this.editedItem.approval_group_ids;
            }
          } catch (error) {
            console.log(error);
          }
          WorkflowService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          WorkflowService.store(this.editedItem).then(
            (response) => {
              console.group("Workflow");
              console.group(response);
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.index();
      }
    },
  },
};
</script>
